.DayPicker {
  @apply inline-block text-base;
}

.DayPicker-wrapper {
  @apply relative flex-row pb-4 select-none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.DayPicker-Months {
  @apply flex flex-wrap justify-center;
}

.DayPicker-Month {
  @apply table my-0 mx-4 mt-4 border-collapse select-none;
  border-spacing: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DayPicker-Caption {
  @apply table-caption mb-3 mt-4;
}

@media (min-width: 420px) {
  .DayPicker-Caption {
    @apply mt-0;
  }
}

.DayPicker-Caption > div {
  @apply font-medium;
}

.DayPicker-Weekdays {
  @apply table-header-group mt-4;
}

.DayPicker-WeekdaysRow {
  @apply table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8b9898;
  text-align: center;
  font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  @apply table-cell min-w-[2.75rem] p-2 align-middle text-center cursor-pointer border border-gray-200 bg-white;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #eaecec;
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4a90e2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: var(--colors-primary);
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #dce0e0;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selection-start):not(
    .DayPicker-Day--selection-end
  ) {
  @apply relative bg-primary bg-opacity-20 text-primary font-medium border-primary border-opacity-10 hover:bg-opacity-30;
}

.DayPicker-Day--selected.DayPicker-Day--outside {
  @apply opacity-70;
}

.DayPicker-Day--selection-start:not(.DayPicker-Day--outside) {
  @apply bg-primary-400 text-white;
}

.DayPicker-Day--selection-end:not(.DayPicker-Day--outside) {
  @apply bg-primary-400 text-white;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  @apply bg-primary-400;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
    .DayPicker-Day--outside
  ):hover {
  @apply bg-primary-400 text-white;
}
